// import React, { useState, useEffect } from "react";
// import SplashScreen from "./SplashScreen";
// import LetterPullup from "./LetterPullup";
// import Footer from "./Footer";
// import axios from "axios";
// import Loader from "./Loader";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const Home = () => {
//   const [showSplash, setShowSplash] = useState(true);
//   const [animateText, setAnimateText] = useState(false);
//   const [showImage, setShowImage] = useState(false);
//   const [showButton, setShowButton] = useState(false);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowSplash(false);
//       setAnimateText(true);
//       setShowImage(true);
//       setShowButton(true);
//     }, 4500);

//     return () => clearTimeout(timer);
//   }, []);

//   const [formData, setFormData] = useState({
//     name: "",
//     phone: "",
//     city: "",
//     email: "",
//     message: "",
//   });
//   const [errors, setErrors] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [submitSuccess, setSubmitSuccess] = useState(null);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const validateForm = () => {
//     const newErrors = {};

//     // Name validation
//     if (!formData.name.match(/^[a-zA-Z ]+$/)) {
//       newErrors.name = "Please enter a valid name (Alphabets Only)";
//     }

//     // Phone validation
//     if (!formData.phone.match(/^\d{10}$/)) {
//       newErrors.phone = "Please enter a valid 10-digit phone number";
//     }

//     // City validation
//     if (!formData.city.match(/^[a-zA-Z ]+$/)) {
//       newErrors.city = "Please enter a valid city (Alphabets Only)";
//     }

//     // Email validation
//     if (!formData.email.match(/^\S+@\S+\.\S+$/)) {
//       newErrors.email = "Please enter a valid email address";
//     }

//     // Message validation
//     if (!formData.message.match(/^[a-zA-Z0-9\s-]+$/)) {
//       newErrors.message = "Please enter a brief about your requirement";
//     }

//     setErrors(newErrors);
//     console.log("Errors:", newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateForm()) return;

//     setLoading(true);
//     setSubmitSuccess(null);
//     try {
//       const response = await axios.post(
//         "https://tinyurl.com/swioapi",
//         formData
//       );
//       toast.success(
//         "Form submitted successfully! Our team will get in touch with you shortly."
//       );
//       setSubmitSuccess(true);
//       console.log(response.data);
//     } catch (error) {
//       toast.error("Failed to submit the form. Please try again.");
//       setSubmitSuccess(false);
//       console.error("There was a problem with the Axios request:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="home">
//       <ToastContainer /> {/* Add ToastContainer here */}
//       {showSplash && <SplashScreen setShowSplash={setShowSplash} />}
//       {!showSplash && (
//         <div className="home-start">
//           <div
//             className={`home-start-left ${
//               animateText ? "animate" : "home-start-left"
//             }`}
//           >
//             <h3>one stop solutions</h3>
//             <h1 id="headh1">
//               a quick break <span className="mobile">&</span>
//               <span className="desktop">
//                 and <br className="mobile" />
//               </span>{" "}
//               <LetterPullup words="we will be back" />
//             </h1>

//             <button
//               id="form-button"
//               className={`${
//                 showButton ? "slide-in-button" : "slide-in-button"
//               }`}
//             >
//               <a href="#conatct-formx">get in touch &#8599;</a>
//             </button>
//           </div>
//           <div
//             className={`home-start-right ${
//               showImage ? "slide-in" : "slide-in"
//             }`}
//           >
//             <img
//               loading="lazy"
//               src="https://cdn.pixabay.com/photo/2017/08/02/00/49/people-2569234_1280.jpg"
//               alt=""
//             />
//           </div>
//         </div>
//       )}
//       {!showSplash && (
//         <div id="conatct-formx">
//           <div className="form">
//             <div className="left">
//               <h3>Let's Connect🤝🏻</h3>
//               <p>Time to Start the Conversation.</p>
//             </div>
//             <div className="right">
//               <form id="form" onSubmit={handleSubmit}>
//                 <div className="form-input-container">
//                   <input
//                     type="text"
//                     name="name"
//                     className="form-control"
//                     id="name"
//                     placeholder=" "
//                     required
//                     value={formData.name}
//                     onChange={handleChange}
//                   />
//                   <label htmlFor="name">NAME</label>
//                   {errors.name && (
//                     <p className="error-message">{errors.name}</p>
//                   )}
//                 </div>

//                 <div className="form-input-container">
//                   <input
//                     type="tel"
//                     name="phone"
//                     className="form-control"
//                     id="phone"
//                     placeholder=" "
//                     required
//                     value={formData.phone}
//                     onChange={handleChange}
//                   />
//                   <label htmlFor="phone">PHONE</label>
//                   {errors.phone && (
//                     <p className="error-message">{errors.phone}</p>
//                   )}
//                 </div>

//                 <div className="form-input-container">
//                   <input
//                     type="text"
//                     name="city"
//                     className="form-control"
//                     id="city"
//                     placeholder=" "
//                     required
//                     value={formData.city}
//                     onChange={handleChange}
//                   />
//                   <label htmlFor="city">CITY</label>
//                   {errors.city && (
//                     <p className="error-message">{errors.city}</p>
//                   )}
//                 </div>

//                 <div className="form-input-container">
//                   <input
//                     type="email"
//                     name="email"
//                     className="form-control"
//                     id="email"
//                     placeholder=" "
//                     required
//                     value={formData.email}
//                     onChange={handleChange}
//                   />
//                   <label htmlFor="email">EMAIL</label>
//                   {errors.email && (
//                     <p className="error-message">{errors.email}</p>
//                   )}
//                 </div>

//                 <div className="form-input-container" id="text-area-container">
//                   <textarea
//                     name="message"
//                     id="message"
//                     rows="3"
//                     className="form-control"
//                     style={{ resize: "none" }}
//                     placeholder="BRIEF ABOUT YOUR REQUIREMENT"
//                     required
//                     value={formData.message}
//                     onChange={handleChange}
//                   ></textarea>
//                   {errors.message && (
//                     <p className="error-message">{errors.message}</p>
//                   )}
//                 </div>

//                 <button
//                   type="submit"
//                   id="form-submit-btn"
//                   className="submit"
//                   disabled={submitSuccess === true}
//                 >
//                   {loading ? (
//                     <Loader />
//                   ) : submitSuccess === true ? (
//                     "Form Submitted"
//                   ) : submitSuccess === false ? (
//                     "Failed to Submit. Try Again"
//                   ) : (
//                     "SUBMIT"
//                   )}
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       )}
//       {!showSplash && <Footer />}
//     </div>
//   );
// };

// export default Home;

import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./home.css";
import NumberTicker from "./NumberTicker";
import businessReg from "../asstes/logos/business registration.png";
// import businessReg from "../../asstes/logos/business registration.png";
import logoBranding from "../asstes/logos/creative-process.png";
import website from "../asstes/logos/coding.png";
import digitalmarketing from "../asstes/logos/network.png";
import creative from "../asstes/logos/creative.png";
import stock from "../asstes/logos/stock.png";
import startegy from "../asstes/logos/strategy.png";
import support from "../asstes/logos/support.png";
import client1 from "../asstes/images/mi.jpg";
import client2 from "../asstes/images/Annapoorna.jpg";
import client3 from "../asstes/images/Capricio.jpg";
import client4 from "../asstes/images/Sahiti.jpg";
import client5 from "../asstes/images/WA.jpg";
import client6 from "../asstes/images/cellbay.jpg";
import client7 from "../asstes/images/hipa.jpg";
import client8 from "../asstes/images/nhrd.jpg";
import Footer from "../footer/Footer";

import ContactForm from "../components/ContactForm";
import SwiperSlideComponent from "../components/SwiperSlideComponent";
import axios from "axios";
import Loader from "../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    city: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    //Name validation
    if (!formData.name.match(/^[a-zA-Z ]+$/)) {
      newErrors.name = "Please enter a valid name (Alphabets Only)";
    }

    //Phone validation
    if (!formData.phone.match(/^\d{10}$/)) {
      newErrors.phone = "Please enter a valid 10-digit phone number";
    }

    //City validation
    if (!formData.city.match(/^[a-zA-Z ]+$/)) {
      newErrors.city = "Please enter a valid city (Alphabets Only)";
    }

    //Email validation
    if (!formData.email.match(/^\S+@\S+\.\S+$/)) {
      newErrors.email = "Please enter a valid email address";
    }

    //Message validation
    if (!formData.message.match(/^[a-zA-Z0-9\s-]+$/)) {
      newErrors.message = "Please enter a brief about your requirement";
    }

    setErrors(newErrors);
    console.log("Errors:", newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setSubmitSuccess(null);
    try {
      const response = await axios.post(
        "https://tinyurl.com/swioapi",
        formData
      );
      toast.success(
        "Form submitted successfully! Our team will get in touch with you shortly."
      );
      setSubmitSuccess(true);
      console.log(response.data);
    } catch (error) {
      toast.error("Failed to submit the form. Please try again.");
      setSubmitSuccess(false);
      console.error("There was a problem with the Axios request:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    AOS.init({ duration: 800 });
  }, []);

  return (
    <div className="main-sec">
      <section className="hero" data-aos="zoom-in-right">
        <h1 id="name">
          <span>Swio.</span> Your One Stop Solution!
        </h1>
        <p>for your business needs....</p>
      </section>
      <section className="quote-section">
        <p>
          An idea is just a daydream when there’s no one to turn it into
          reality! Swio was started with a vision to be the ultimate start-up
          hub for all businesses!
        </p>
      </section>
      <section className="hero2">
        <p data-aos="zoom-in-up">START WORKING WITH US</p>
        <h2 data-aos="zoom-in-up">
          You, bring your <br className="mobile" /> idea,
          <br className="laptop" /> We, make it <br className="mobile" />
          <span style={{ color: "#d92027" }}> Live.</span>
        </h2>
        <a href="#formtostartconversation">
          <button>get started</button>
        </a>
      </section>

      <section className="main-services">
        <p data-aos="zoom-in-up" className="services-heading ourservicesmodifyhead">
          services
        </p>
        <section className="services">
          <div data-aos="zoom-in-up">
            <img src={businessReg} alt="businessres" />
            <p>Business Registration & Compliance</p>
          </div>
          <div data-aos="zoom-in-up">
            <img src={logoBranding} alt="logobranding" />
            <p>Logo & Branding Solutions</p>
          </div>
          <div data-aos="zoom-in-up">
            <img src={website} alt="websitedev" />
            <p>Website Dovelepment</p>
          </div>
          <div data-aos="zoom-in-up">
            <img src={digitalmarketing} alt="advancedm" />
            <p>Advanced Digital Marketing</p>
          </div>
        </section>
        <p data-aos="zoom-in-up" className="services-heading ourrecordsheading">
          our records
        </p>
        <section className="ourrecords">
          <div data-aos="zoom-in-up">
            <article>
              <NumberTicker
                value={150}
                direction="up"
                delay={1}
                className="my-custom-class"
              />
              <span style={{ fontSize: "40px" }}>+</span>
            </article>

            <p>Registrations Filed</p>
          </div>
          <div data-aos="zoom-in-up">
            <article>
              <NumberTicker
                value={500}
                direction="up"
                delay={1}
                className="my-custom-class"
              />
              <span style={{ fontSize: "40px" }}>+</span>
            </article>
            <p>Websites Developed</p>
          </div>
          <div data-aos="zoom-in-up">
            <article>
              <NumberTicker
                value={15}
                direction="up"
                delay={1}
                className="my-custom-class"
              />
              <span style={{ fontSize: "40px" }}>K+</span>
            </article>
            <p>Designs Created</p>
          </div>
          <div data-aos="zoom-in-up">
            <article>
              <NumberTicker
                value={10}
                direction="up"
                delay={0.5}
                className="my-custom-class"
              />
              <span style={{ fontSize: "40px" }}>L+</span>
            </article>

            <p>People Reached</p>
          </div>
        </section>
      </section>

      <section className="contnetadd">
        <h2>What makes us different</h2>
        <SwiperSlideComponent />
      </section>

      <section className="clients">
        <h2 data-aos="fade-right" className="ourcientsh2">
          Start working with us
        </h2>
        <h4 data-aos="fade-right" className="ourcientsh4">
          Our clients
        </h4>

        <div className="clients-container">
          <img data-aos="zoom-out-up" src={client1} alt="" />
          <img data-aos="zoom-out-up" src={client2} alt="" />
          <img data-aos="zoom-out-up" src={client3} alt="" />

          <img data-aos="zoom-out-up" src={client4} alt="" />

          <img data-aos="zoom-out-up" src={client5} alt="" />

          <img data-aos="zoom-out-up" src={client6} alt="" />

          <img data-aos="zoom-out-up" src={client7} alt="" />

          <img data-aos="zoom-out-up" src={client8} alt="" />
        </div>
        <pre>And many more.....</pre>
      </section>
      <section className="talktous">
        <p data-aos="zoom-in-up">ARE YOU FACING ISSUES TO BUILD YOUR BRAND?</p>
        <h4 data-aos="zoom-in-up">
          need <br /> help?
        </h4>
        <a href="#formtostartconversation">
          <button>ask question</button>
        </a>
      </section>
      <section className="conatcformhomesec" id="formtostartconversation">
        <div id="conatct-formx">
          <div className="form">
            <div className="left">
              <h3>Connect with Us 👋</h3>
              <p>Let’s Begin the Conversation.</p>
            </div>
            <div className="right">
              <form id="form" onSubmit={handleSubmit}>
                <div className="form-input-container">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="nameforform"
                    placeholder=" "
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <label htmlFor="nameforform">NAME</label>
                  {errors.name && (
                    <p className="error-message">{errors.name}</p>
                  )}
                </div>

                <div className="form-input-container">
                  <input
                    type="tel"
                    name="phone"
                    className="form-control"
                    id="phone"
                    placeholder=" "
                    required
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <label htmlFor="phone">PHONE</label>
                  {errors.phone && (
                    <p className="error-message">{errors.phone}</p>
                  )}
                </div>

                <div className="form-input-container">
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    id="city"
                    placeholder=" "
                    required
                    value={formData.city}
                    onChange={handleChange}
                  />
                  <label htmlFor="city">CITY</label>
                  {errors.city && (
                    <p className="error-message">{errors.city}</p>
                  )}
                </div>

                <div className="form-input-container">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="email"
                    placeholder=" "
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <label htmlFor="email">EMAIL</label>
                  {errors.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>

                <div className="form-input-container" id="text-area-container">
                  <textarea
                    name="message"
                    id="message"
                    rows="3"
                    className="form-control"
                    style={{ resize: "none" }}
                    placeholder="BRIEF ABOUT YOUR REQUIREMENT"
                    required
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && (
                    <p className="error-message">{errors.message}</p>
                  )}
                </div>

                <button
                  type="submit"
                  id="form-submit-btn"
                  className="submit"
                  disabled={submitSuccess === true}
                >
                  {loading ? (
                    <Loader />
                  ) : submitSuccess === true ? (
                    "Form Submitted"
                  ) : submitSuccess === false ? (
                    "Failed to Submit. Try Again"
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* <div className="fromconatiner">
          <ContactForm data-aos="zoom-in-up" />
        </div>
        <iframe
          className="location"
          data-aos="zoom-in-up"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.222489884733!2d78.48673507368963!3d17.401107102412603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9920d1083dcb%3A0x562e493e4d1ed82a!2sSwio%20-%20One%20Stop%20Solution%20-%20Digital%20Marketing!5e0!3m2!1sen!2sin!4v1723464277833!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        /> */}
      </section>
      <Footer />
    </div>
  );
};

export default Home;
