import React from "react";
import Home from "./home/Home";
import Header from "./components/Header";


const App = () => {
  return (
    <>
      <Header />
      <Home />
    </>
  );
};

export default App;
