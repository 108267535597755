import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  contactNo: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits")
    .required("Contact number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  location: Yup.string().required("Location is required"),
  choice: Yup.string().required("Please select an option"),
});

const ContactForm = () => {
  const [formDisabled, setFormDisabled] = useState(false);

  return (
    <Formik
      initialValues={{
        name: "",
        contactNo: "",
        email: "",
        location: "",
        choice: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        setStatus("loading");
        try {
          const response = await axios.post(
            "https://tinyurl.com/swioapi",
            values
          );
          console.log("Response:", response.data);
          setStatus("submitted");
          setFormDisabled(true); 
        } catch (error) {
          console.error("Error submitting form:", error);
          setStatus("failed");
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, status }) => (
        <Form className="formcontact">
          <div>
            <Field
              type="text"
              name="name"
              className="feild"
              placeholder="Name"
              disabled={formDisabled}
            />
            <ErrorMessage name="name" component="div" className="error" />
          </div>

          <div>
            <Field
              type="text"
              name="contactNo"
              className="feild"
              placeholder="Contact No"
              disabled={formDisabled}
            />
            <ErrorMessage name="contactNo" component="div" className="error" />
          </div>

          <div>
            <Field
              type="email"
              name="email"
              className="feild"
              placeholder="Email"
              disabled={formDisabled}
            />
            <ErrorMessage name="email" component="div" className="error" />
          </div>

          <div>
            <Field
              type="text"
              name="location"
              className="feild"
              placeholder="Location"
              disabled={formDisabled}
            />
            <ErrorMessage name="location" component="div" className="error" />
          </div>

          <div>
            <Field
              as="select"
              name="choice"
              className="feild"
              disabled={formDisabled}
            >
              <option value="" label="Select purpose " />
              <option value="option1" label="Option 1" />
              <option value="option2" label="Option 2" />
            </Field>
            <ErrorMessage name="choice" component="div" className="error" />
          </div>

          <button type="submit" disabled={isSubmitting || formDisabled} className="feild">
            <div className="button-content">
              {status === "loading" && <div className="loader"></div>}
              {status === "submitted" && "Submitted successfully"}
              {status === "failed" && "Failed to Submit"}
              {status !== "loading" &&
                status !== "submitted" &&
                status !== "failed" &&
                "Connect with Us"}
            </div>
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
