import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Core Swiper styles
import "swiper/css/navigation"; // Optional Navigation styling
import "swiper/css/pagination"; // Optional Pagination styling
import "swiper/css/autoplay"; // Optional Autoplay feature
import "./slider.css";

import { Navigation, Pagination, Autoplay } from "swiper/modules";

import powerOfContnet from "../asstes/sliderimg/powerOfContnet.png";
import perferctStartgies from "../asstes/sliderimg/Good team-pana.png";
import marketTrends from "../asstes/sliderimg/marketTrends.png";
import awardWinningTeam from "../asstes/sliderimg/awardWinningTeam.png";

const SwiperSlideComponent = () => {
  return (
    <div className="swiper-container">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        // navigation={true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        style={{
          width: "100%",
        }}
      >
        {/* Slides */}
        <SwiperSlide>
          <div className="sliderDivdifferntsec">
            <img src={perferctStartgies} alt="perferct Startgies" />
            <div>
              <h4>Perfect Startegies</h4>
              <p>
                Incorporating content to startegic marketing to create campaigns
                that work everytime.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="sliderDivdifferntsec">
            <img src={powerOfContnet} alt="power Of Contnet" />
            <div>
              <h4>Power Of Content</h4>
              <p>
                Meeting the ever lasting reservoir content hunger of consumers
                with captivating content.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="sliderDivdifferntsec">
            <img src={marketTrends} alt="market Trends" />
            <div>
              <h4>Market Trends</h4>
              <p>
                Our market trends team excels not only in fallowing leading
                market trends but setting new ones.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="sliderDivdifferntsec">
            <img src={awardWinningTeam} alt="awardWinningTeam" />
            <div>
              <h4>Award Winning Team</h4>
              <p>
                We don't need any awards other than our satisfied clients as
                they give bigger awards than any.
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SwiperSlideComponent;
